<template>
    <div id="fMessage" class="container pt-5">
        <div class="card shadow-sm">
            <div class="card-title">
                <div class="pl5">
                    <span class="shadow-sm p-4 bg-primary rounded-3">
                        <font-awesome-icon :icon="['fas', 'comment-alt']" size="lg" class="text-white" />
                    </span>
                    <span class="fw"> &nbsp;&nbsp;MESSAGE SENDER</span>
                    <span class="float-end p-2 text-black-50">
                        <button class="btn btn-default">
                            <div class="small">Add message</div>
                        </button>
                    </span>
                </div>
            </div>
            <div class="card-body pt-0 pb-2 small text-black-50">
                <table>
                    <tbody>
                        <tr v-for="item in items" :key="item">
                            <td>{{item}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            items:[]
        }
    }
}
</script>
<style scoped>
    .pl5 {
        margin-left: 15px;
    }
    .fw{
        font-weight: normal;
    }
</style>