<template>
    <div class="container mt-3">
        <div class="row">
            <div class="col-sm-3 mb-5">
                <div class="card shadow-sm">
                    <div class="card-title">
                        <div class="pl5">
                            <span class="shadow-sm p-4 b-green rounded-3">
                                <font-awesome-icon icon="users" size="lg" class="text-white" />
                            </span>
                            <span class="float-end p-2 text-black-50">
                                <div class="small">Member</div>
                                <div class="text-dark text-right">
                                    <span class="mfong">+0</span>
                                </div>
                            </span>
                        </div>
                    </div>
                    <div class="card-body pt-0 pb-2 small text-black-50">
                        <font-awesome-icon icon="id-badge" style="font-size:15px" class="text-black-50 mr-2" /> Just Updated
                    </div>
                </div>
            </div>
            <div class="col-sm-3 mb-5">
                <div class="card shadow-sm">
                    <div class="card-title">
                        <div class="pl5">
                            <span class="shadow-sm p-4 bg-danger rounded-3">
                                <font-awesome-icon icon="user-clock" size="lg" class="text-white" />
                            </span>
                            <span class="float-end p-2 text-black-50">
                                <div class="small">Daily Visit</div>
                                <div class="text-dark text-right">
                                    <span class="mfong">0</span>
                                </div>
                            </span>
                        </div>
                        
                    </div>
                    <div class="card-body pt-0 pb-2 small text-black-50">
                        <font-awesome-icon icon="tag" style="font-size:15px" class="text-black-50 mr-2" /> Tracked access Site
                    </div>
                </div>
            </div>
            <div class="col-sm-3 mb-5">
                <div class="card shadow-sm">
                    <div class="card-title">
                        <div class="pl5">
                            <span class="shadow-sm p-4 b-green rounded-3">
                                <font-awesome-icon icon="users" size="lg" class="text-white" />
                            </span>
                            <span class="float-end p-2 text-black-50">
                                <div class="small">Member</div>
                                <div class="text-dark text-right">
                                    <span class="mfong">+0</span>
                                </div>
                            </span>
                        </div>
                        
                    </div>
                    <div class="card-body pt-0 pb-2 small text-black-50">
                        <font-awesome-icon icon="id-badge" style="font-size:15px" class="text-black-50 mr-2" /> Register in the system
                    </div>
                </div>
            </div>
            <div class="col-sm-3 mb-5">
                <div class="card shadow-sm">
                    <div class="card-title">
                        <div class="pl5">
                            <span class="shadow-sm p-4 b-green rounded-3">
                                <font-awesome-icon icon="users" size="lg" class="text-white" />
                            </span>
                            <span class="float-end p-2 text-black-50">
                                <div class="small">Member</div>
                                <div class="text-dark text-right">
                                    <span class="mfong">+0</span>
                                </div>
                            </span>
                        </div>
                        
                    </div>
                    <div class="card-body pt-0 pb-2 small text-black-50">
                        <font-awesome-icon icon="id-badge" style="font-size:15px" class="text-black-50 mr-2" /> Register in the system
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                
            </div>
        </div>
    </div>
</template>
<script>
//import messageview from '../tipexpress/messageview.vue'    
export default {
    components:{
        //messageview
    },
    props: {

    }
}
</script>
<style scoped>
    .mfong {
        font-size: 25px;
        text-align: right;
        display: block;
    }
    .pl5 {
        margin-left: 15px;
    }
    .b-green {
        background: linear-gradient(60deg,#66bb6a,#43a047);
    }
</style>