<template>
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="card shadow-sm">
                    <div class="card-title">
                        <div class="pl5">
                            <span class="shadow-sm p-4 bg-info rounded-3">
                                <font-awesome-icon icon="chart-line" size="lg" class="text-white" />
                            </span>
                            <span class="p-2">Daily register</span>
                        </div>
                    </div>
                    <div class="card-body">x</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style scoped>
    .mfong {
        font-size: 25px;
        text-align: right;
        display: block;
    }
    .pl5 {
        margin-left: 15px;
    }
</style>