<template>
    <div class="container-fulid">
        <tiptop caption="Deshboad"></tiptop>
        <tipcard></tipcard>
        <tipline></tipline>
        <messageview></messageview>
    </div>
</template>
<script>
import tiptop from '../tipexpress/tiptop.vue'
import tipcard from '../tipexpress/tipcard.vue'
import tipline from '../tipexpress/tipline.vue'
import messageview from '../tipexpress/messageview.vue'    
export default {
    components: {
        tiptop,
        tipcard,
        tipline,
        messageview,
    }
}
</script>
<style scoped>

</style>