<template>
    <div>
        <div class="p-2 pb-0 caption">{{caption}}</div>
        <div class="p-2 pt-0">
            <nav aria-label="breadcrumb" class="pl-3">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">Home</li>
                    <li class="breadcrumb-item active" aria-current="page">Deshboard</li>
                </ol>
            </nav>            
        </div>
    </div>
</template>
<script>
export default {
    props: {
        caption: String
    }
}
</script>
<style scoped>
    .caption {
        font-size: 30px;
    }
</style>